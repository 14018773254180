<template>
  <div class="footer">
    <!-- <div class="footer-img">
      <img src="https://obs-hsss.obs.cn-north-1.myhuaweicloud.com/admin/images/com-huasheng/qywx.png" alt="">
      <img src="https://obs-hsss.obs.cn-north-1.myhuaweicloud.com/admin/images/com-huasheng/gzh.png" alt="">
      <img src="https://obs-hsss.obs.cn-north-1.myhuaweicloud.com/admin/images/com-huasheng/dy.png" alt="">
    </div> -->
	<div class="footer-content">
		<div class="footer-top">
			<span style="color: #fff;font-size: .24rem;">客服电话：0533-6259888</span>
		</div>
		<div class="footer-content-navList-qrCode">
			<div>
				<img class="footer-img"
					src="https://obs-hsss.obs.cn-north-1.myhuaweicloud.com/admin/images/com-huasheng/qywx.png"
					alt="">
				<div class="footer-relation">
					购买咨询
				</div>
			</div>
			<div>
				<img class="footer-img"
					src="https://obs-hsss.obs.cn-north-1.myhuaweicloud.com/admin/images/com-huasheng/gzh.png" alt=""
					style="margin: 0 .3rem">
				<div class="footer-relation">
					公众号
				</div>
			</div>
			<!-- <div>
				<img class="footer-img"
					src="https://obs-hsss.obs.cn-north-1.myhuaweicloud.com/admin/images/com-huasheng/dy.png" alt="">
				<div class="footer-relation">
					抖音
				</div>
			</div> -->
	
	
		</div>
	</div>
	
    <div class="footer-info">
      <span>Copyright © 2022</span>
      <span style="margin: 0 .15rem 0 .1rem">山东华盛智慧网络科技有限公司</span>
      <!-- <span>客服电话：0533-6259888</span> -->
      <span style="margin-right: .2rem">All Rights Reserved.</span>
      <span>鲁ICP备18034819号-1</span>
	  <span>统一社会信用代码：91370322MA3N0QFP45</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "navFooter"
}
</script>

<style scoped lang="less">
  .footer{
    width: 100%;
    height: 3.6rem;
    background-color: #0F35E2;
    box-sizing: border-box;
    padding: .4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-img{
      width: 4rem;
      display: flex;
      justify-content: space-between;

      img{
        width: 1rem;
        height: 1rem;
      }
    }

    &-info{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffffff;
      font-size: .28rem;
      // margin-top: .2rem;
    }
  }
  
  .footer {
  	width: 100%;
  	padding: 0;
  	background: #0F35E2;
  
  	&-content {
  		width: 7.5rem;
  		margin: 0 auto;
  		justify-content: center;
  		display: flex;
  		align-items: center;
  
  		&-navList {
  			width: 100%;
  			height: .5rem;
  			display: flex;
  			align-items: center;
  			justify-content: center;
  			border-bottom: 1px solid #3A5AE5;
  
  			&-item {
  				color: #ffffff;
  				font-size: .16rem;
  				margin-right: .5rem;
  
  				&:hover {
  					cursor: pointer;
  					text-decoration: underline;
  				}
  			}
  
  			&-qrCode {
  				width: 70%;
  				// padding: .3rem 0;
  				display: flex;
  				align-items: center;
  				justify-content: center;
  
  				>img {
  					width: 1.2rem;
  					height: 1.2rem;
  				}
  			}
  		}
  	}
  
  	&-bottom {
  		width: 100%;
  		height: .7rem;
  		display: flex;
  		align-items: center;
  		justify-content: center;
  		color: #ffffff;
  		font-size: .16rem;
  		border-top: 1px solid #3A5AE5;
  	}
  }
  
  .hotLine {
  	font-size: 28px;
  	font-weight: bold;
  	color: #000;
  	padding: 0 11px;
  }
  
  .phone {
  	font-size: 34px;
  	color: #36d;
  	font-weight: bold;
  	padding: 10px 11px;
  }
  
  .particulars {
  	padding: 10px 11px;
  }
  
  .index-button {
  	position: fixed;
  	z-index: 9999;
  	right: 20px;
  	top: 50%;
  }
  
  .index-button-content {
  	background: #36d;
  	padding: 20px;
  	color: #a1a1a1;
  }
  
  .index-button-img {
  	// width: 270px;
  	height: 230px;
  }
  
  // .dialog-footer {
  // 	margin-top: -110px;
  // }
  
  .footer-top {
  	padding: .3rem 0;
  	display: grid;
  }
  
  .footer-img {
  	width: 1rem;
  	height: 1rem;
  }
  
  .footer-relation {
  	margin-top: 2px;
  	font-size: .16rem;
  	color: #fff;
  	display: grid;
  	justify-content: center;
  }
  
</style>
