import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/trends',
    name: 'trends',
    component: () => import('../views/trends/index.vue')
  },
  {
    path: '/trendsDetail',
    name: 'trendsDetail',
    component: () => import('../views/trends/detail.vue')
  },
  {
    path: '/functions',
    name: 'functions',
    component: () => import('../views/functions/index.vue')
  },
  {
    path: '/softness',
    name: 'softness',
    component: () => import('../views/softness/index.vue')
  },
  {
    path: '/honor',
    name: 'honor',
    component: () => import('../views/honor/index.vue')
  },
  {
    path: '/us',
    name: 'us',
    component: () => import('../views/us/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
