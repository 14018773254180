import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Rem from "@/utils/rem";
import Vant from 'vant';
import 'vant/lib/index.css';
import navHeader from "@/components/navHeader.vue";
import navFooter from "@/components/navFooter.vue";
import { Lazyload } from 'vant';

Vue.use(Lazyload);

Vue.use(Vant);

Vue.config.productionTip = false

window.addEventListener('load', Rem.Rem);
window.addEventListener('resize', Rem.Rem)
Rem.Rem();

Vue.component("navHeader",navHeader)
Vue.component("navFooter",navFooter)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
