<template>
  <div class="header" :style="{ backgroundColor: bgColor,position: fixed ? 'fixed' : 'absolute' }">
    <van-image :show-loading="false" :show-error="false" :src="src" class="logo" @click="goHome"></van-image>
    <div class="header-right">
      <span :style="{ color: bgColor == 'transparent' ? '#ffffff' : '#333333' }" @click="show = true">了解更多</span>
      <van-image :show-loading="false" :show-error="false" :src="cate" class="cate" @click="show = true"></van-image>
    </div>

    <van-popup
      v-model="show"
      position="left"
      :style="{ width: '4rem',height: '100vh',textAlign: 'left' }"
    >
      <van-cell v-for="(item,index) in list" :key="index" :title="item.text" :to="item.path" is-link />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "navHeader",
  props: {
    src: {
      type: String,
      default: 'https://obs-hsss.obs.cn-north-1.myhuaweicloud.com/admin/images/com-huasheng-m/logo-w.png'
    },
    cate: {
      type: String,
      default: 'https://obs-hsss.obs.cn-north-1.myhuaweicloud.com/admin/images/com-huasheng-m/cate-w.png'
    },
    bgColor: {
      type: String,
      default: "transparent"
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      show: false,
      list:[
        {
          text: "华盛科技",
          path: "/"
        },
        {
          text: "公司动态",
          path: "/trends"
        },
        {
          text: "产品服务",
          path: "/functions"
        },
        {
          text: "研发实力",
          path: "/softness"
        },
        {
          text: "荣誉证书",
          path: "/honor"
        },
        {
          text: "企业简介",
          path: "/us"
        }
      ]
    }
  },
  methods: {
    goHome(){
      this.$router.push("/")
    }
  }
}
</script>

<style scoped lang="less">
  .header{
    width: 100%;
    box-sizing: border-box;
    padding: 0 .32rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999;

    &-right{
      display: flex;
      align-items: center;

      >span{
        margin-right: .08rem;
        font-size: .28rem;
      }
    }

    .logo{
      width: 2.5rem;
      object-fit: contain;
    }

    .cate{
      width: .48rem;
      object-fit: contain;
    }
  }
</style>
